import React from "react"
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper"
import { Col, Container, Row } from "react-bootstrap"

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderOne = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    autoplay: {
      delay: 5000,
    },
  };
  return (
    <section style={{ background: '#5cc6d0', paddingTop: 160 }}>
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <iframe src="https://forms.monday.com/forms/embed/19153d8e61e0ee4594a3743df0e59e73?r=use1" width="650" height="1850" style={{ border: 0 }} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SliderOne;
